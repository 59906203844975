import React from "react"
import overUCover from "../images/overu.webp"

const Hero = () => {
  return (
    <div id={"hero-image-container"}>
      <a href={"https://bfan.link/over-u"} target={"_blank"}>
        <img src={overUCover} id={"hero-image"} />
      </a>
    </div>
  )
}

export default Hero
