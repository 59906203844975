import React from "react"
import { motion } from "framer-motion"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Hero from "../components/Hero"
import mesh from "../images/mesh-gradient.png"

const IndexPage = () => {
  const socialContainerVariants = {
    initial: {},
    loaded: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const socialVariants = {
    initial: {
      opacity: 0,
      color: "#fff",
    },
    loaded: {
      opacity: 1,
      color: "#fff",
    },
    hovered: {
      color: "#000000",
    },
  }

  return (
    <Layout>
      <SEO title="Home" />
      <motion.div id={"home-container"}>
        <motion.h1
          id={"artist-title"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          PAT LOK
        </motion.h1>

        <motion.h1
          id={"release-title"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          Over U
        </motion.h1>

        <Hero />

        <img src={mesh} style={{ position: "absolute" }} />

        <motion.div
          id={"social-link-container"}
          variants={socialContainerVariants}
          initial="initial"
          animate="loaded"
        >
          <a href={"https://www.instagram.com/patlokmusic/"} target={"_blank"}>
            <motion.h5 variants={socialVariants} whileHover={"hovered"}>
              Instagram
            </motion.h5>
          </a>
          <a
            href={"https://open.spotify.com/artist/3ZPRZDAAuBrvx1tsIjeFxh"}
            target={"_blank"}
          >
            <motion.h5 variants={socialVariants} whileHover={"hovered"}>
              Spotify
            </motion.h5>
          </a>
          <a href={"https://www.twitch.tv/patlokmusic"} target={"_blank"}>
            <motion.h5 variants={socialVariants} whileHover={"hovered"}>
              Twitch
            </motion.h5>
          </a>
          <a href={"https://www.facebook.com/PatLokMusic"} target={"_blank"}>
            <motion.h5 variants={socialVariants} whileHover={"hovered"}>
              Facebook
            </motion.h5>
          </a>
          <a href={"https://twitter.com/PatLokMusic"} target={"_blank"}>
            <motion.h5 variants={socialVariants} whileHover={"hovered"}>
              Twitter
            </motion.h5>
          </a>
          <a href={"https://soundcloud.com/patlok"} target={"_blank"}>
            <motion.h5 variants={socialVariants} whileHover={"hovered"}>
              Soundcloud
            </motion.h5>
          </a>
          <a
            href={"https://www.youtube.com/user/patrickzlok"}
            target={"_blank"}
          >
            <motion.h5 variants={socialVariants} whileHover={"hovered"}>
              YouTube
            </motion.h5>
          </a>
        </motion.div>
      </motion.div>
    </Layout>
  )
}

export default IndexPage
